<template>
  <v-row>
    <v-col
      cols="12"
    >
      <base-card>
        <v-card-title>Abschlussnoten</v-card-title>
        <v-card-text>
          <v-row
            align="center"
            class="ml-2"
          >
            <v-col
              class="d-flex"
              cols="11"
              sm="5"
            >
              <v-select
                :items="subjects"
                label="Diplom"
                dense
              />
            </v-col>
          </v-row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Fach
                  </th>
                  <th class="text-left">
                    Note
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in courses"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.mark }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  export default {

    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'QuarterMarksPMA',
    },
    data () {
      return {
        subjects: [
          'KV Ausbildungen',
          'HR & Personal',
        ],
        courses: [
          {
            name: 'Administration & Handel',
            mark: 6.0,
            date: '01.06.2021',
          },
          {
            name: 'Administration',
            mark: 5.5,
            date: '02.06.2021',
          },
          {
            name: 'Wirtschaft und Gesellschaft',
            mark: 5.5,
            date: '03.06.2021',
          },
          {
            name: 'Kommunikationstechnologien',
            mark: null,
            date: '01.06.2021',
          },
          {
            name: 'Informatik',
            mark: null,
            date: '02.06.2021',
          },
          {
            name: 'Mathematik',
            mark: null,
            date: '03.06.2021',
          },
        ],
      }
    },
  }
</script>
